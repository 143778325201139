<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/personInfo">教职工管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <div>
        <el-form-item label="编号" prop="id">
          <el-input type="text" size="mini" v-model="queryModel.id"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证" prop="idCard">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.idCard"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="changePage(1)"
            :loading="loading"
            >查询</el-button
          >&nbsp;
          <el-button
            type="info"
            size="mini"
            style="margin-left: 8px"
            @click="handleReset('queryForm')"
            >重置</el-button
          >&nbsp;
        </el-form-item>
      </div>
      <div>
        <el-form-item label="学校" prop="companyCode">
          <!-- <el-select
            v-model="queryModel.companyCode"
            size="mini"
            filterable
            placeholder="请选择"
            style="width:220px"
          >
            <el-option
              v-for="company in companyResult"
              :key="company.id"
              :label="company.name"
              :value="company.code"
            ></el-option>
          </el-select>-->
          <el-select-tree
            size="mini"
            :props="props"
            :options="companyResult"
            v-model="queryModel.parentId"
            height="200"
          ></el-select-tree
          >&nbsp;
          <el-checkbox v-model="queryModel.subordinate"
            >是否包含下级单位</el-checkbox
          >
        </el-form-item>
        <el-form-item label="是否上传照片" prop="isUploadPhoto">
          <el-select
            v-model="queryModel.isUploadPhoto"
            size="mini"
            filterable
            placeholder="请选择"
            style="width: 120px"
          >
            <el-option value label="全部"></el-option>
            <el-option value="1" label="已上传"></el-option>
            <el-option value="0" label="未上传"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否同步" prop="faceBound">
          <el-select
            v-model="queryModel.faceBound"
            size="mini"
            filterable
            placeholder="请选择"
            style="width: 120px"
          >
            <el-option value label="全部"></el-option>
            <el-option value="1" label="已同步"></el-option>
            <el-option value="0" label="未同步"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联设备名称" prop="derviceName">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.derviceName"
          ></el-input>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="人员状态" prop="delFlag">
          <el-select
            v-model="queryModel.delFlag"
            size="mini"
            filterable
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option value="0" label="正常"></el-option>
            <el-option value="1" label="已删除"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份" prop="popedom">
          <el-select
            v-model="queryModel.popedom"
            size="mini"
            filterable
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option label="全部" value="3,4,5,6,9"></el-option>
            <el-option label="班主任" value="20"></el-option>
            <el-option label="教职工" value="3"></el-option>
            <el-option label="司机" value="5"></el-option>
            <el-option label="照看员" value="6"></el-option>
            <el-option label="监管人员" value="9"></el-option>
            <el-option label="其他职业" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职位" prop="jobType">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.jobType"
          ></el-input>
        </el-form-item>
        <el-form-item label="班级" prop="companyClassName">
          <el-select-tree
            size="mini"
            :props="props"
            :options="companyResult"
            v-model="queryModel.companyClassName"
            height="200"
          ></el-select-tree
          >&nbsp;
        </el-form-item>
        <!-- <el-form-item label="健康码" prop="healthyCode">
          <el-select
            v-model="queryModel.healthyCode"
            size="mini"
            placeholder="请选择"
            style="width:100px"
          >
          <el-option
              v-for="item in healthyCodeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
          ></el-option>
          </el-select>
        </el-form-item> -->
      </div>
      <!-- <div>
        <el-form-item v-if="position1Show" :label="position1" prop="position1">
          <el-input type="text" size="mini" v-model="queryModel.position1"></el-input>
        </el-form-item>
        <el-form-item v-if="position2Show" :label="position2" prop="position2">
          <el-input type="text" size="mini" v-model="queryModel.position2"></el-input>
        </el-form-item>
        <el-form-item v-if="position3Show" :label="position3" prop="position3">
          <el-input type="text" size="mini" v-model="queryModel.position3"></el-input>
        </el-form-item>
        <el-form-item v-if="position4Show" :label="position4" prop="position4">
          <el-input type="text" size="mini" v-model="queryModel.position4"></el-input>
        </el-form-item>
        <el-form-item v-if="position5Show" :label="position5" prop="position5">
          <el-input type="text" size="mini" v-model="queryModel.position5"></el-input>
        </el-form-item>
      </div> -->
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchClearFaceImg"
        >清除设备照片</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-upload2"
        @click="batchImportVisible = true"
        >导入</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="xlsLoading"
        @click="downloadAll"
        v-show="batchExportXls"
      >导出数据</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-refresh"
        :disabled="multipleSelection.length == 0"
        @click="dataSync"
        >数据同步</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-refresh"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchEnabledFace"
        >开启自动同步</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-refresh"
        :disabled="multipleSelection.length == 0"
        @click="batchBoundDevice = true"
        >关联设备</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-refresh"
        :disabled="multipleSelection.length == 0"
        @click="batchUnBoundDevice = true"
        >解绑设备</el-button
      >
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="id"
        label="编号"
        width="80"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="100"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="faceImageUrl"
        label="人员照片"
        width="80"
        fixed="left"
      >
        <template slot-scope="{ row }">
          <a :href="row.faceImageUrl" target="_blank">
            <el-avatar
              :size="48"
              shape="circle"
              :src="
                row.faceImageUrl +
                '?x-oss-process=image/resize,m_fill,w_64,h_64'
              "
              :key="row.id"
            ></el-avatar>
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="companyName"
        label="单位"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="defaultClassName"
        label="班级"
        width="160"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="teacherInfo.jobType" label="职位" width="120">
        <template slot-scope="{ row }">
          <span
            v-if="row.defaultPersonCompany != null && row.teacherInfo != null"
            >{{
              row.defaultPersonCompany.isClassTeacher
                ? "班主任"
                : row.teacherInfo.jobType
            }}</span
          >
          <span
            v-if="row.defaultPersonCompany == null && row.teacherInfo != null"
            >{{ row.teacherInfo.jobType }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="phone"
        label="手机号"
        width="180"
      ></el-table-column>
      <el-table-column prop="popedom" label="用户身份" width="200">
        <template slot-scope="{ row }">
          <el-row>
            <el-col :span="10" v-if="row.popedom.indexOf('1') != -1"
              >学生</el-col
            >
            <el-col :span="10" v-if="row.popedom.indexOf('2') != -1"
              >家长</el-col
            >
            <el-col
              :span="10"
              v-if="
                row.popedom.indexOf('3') != -1 &&
                row.defaultPersonCompany != null
              "
              >老师</el-col
            >
            <el-col
              :span="10"
              v-if="
                row.popedom.indexOf('3') != -1 &&
                row.defaultPersonCompany == null
              "
              >职工</el-col
            >
            <el-col :span="10" v-if="row.popedom.indexOf('5') != -1"
              >司机</el-col
            >
            <el-col :span="10" v-if="row.popedom.indexOf('6') != -1"
              >照看员</el-col
            >
            
            <!-- <el-col :span="10" v-if="row.popedom.indexOf('4')!= -1">其他</el-col> -->
          </el-row>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="healthyCodeN" label="健康码" width="80" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span v-if="row.healthyCode">
            <span v-if="row.healthyCode == '00'" style="color:green">绿码</span>
            <span v-else-if="row.healthyCode == '01'" style="color:yellow">黄码</span>
            <span v-else-if="row.healthyCode == '02'" style="color:red">红码</span>
            <span v-else style="color:gray">灰码</span>
          </span>
        </template>
      </el-table-column> -->
      <!-- <el-table-column prop="healthyReason" label="健康码原因" width="150" show-overflow-tooltip></el-table-column> -->
      <el-table-column
        prop="idCard"
        label="身份证"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="deviceName"
        label="关联设备"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <!-- <el-table-column prop="position1" :label="position1" width="180" v-if="position1Show"></el-table-column>
      <el-table-column prop="position2" :label="position2" width="180" v-if="position2Show"></el-table-column>
      <el-table-column prop="position3" :label="position3" width="180" v-if="position3Show"></el-table-column>
      <el-table-column prop="position4" :label="position4" width="180" v-if="position4Show"></el-table-column>
      <el-table-column prop="position5" :label="position5" width="180" v-if="position5Show"></el-table-column> -->
      <el-table-column prop="openId" label="是否关联公众号" width="180">
        <template slot-scope="{ row }">{{
          row.openId == null ? "否" : "是"
        }}</template>
      </el-table-column>
      <el-table-column prop="faceBound" label="是否同步" width="180">
        <template slot-scope="{ row }">{{
          row.faceBound ? "是" : "否"
        }}</template>
      </el-table-column>
      <el-table-column label="是否自动同步" width="120">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.isSync"
            @change="enabledTo(row, 'sync', row.faceEnabled)"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="disabled"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="人脸授权">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.faceEnabled"
            @change="enabledTo(row, 'face', row.faceEnabled)"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="disabled"
          ></el-switch>
        </template>
      </el-table-column>
      <!-- <el-table-column label="刷卡授权">
        <template slot-scope="{row}">
          <el-switch
            v-model="row.cardEnabled"
            @change="enabledTo(row,'card','')"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="disabled"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="手机授权">
        <template slot-scope="{row}">
          <el-switch
            v-model="row.appEnabled"
            @change="enabledTo(row,'app','')"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="disabled"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="密码">
        <template slot-scope="{row}">
          <el-switch v-model="row.passwordEnabled" @change="enabledTo(row.id,'face')" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="访客授权">
        <template slot-scope="{row}">
          <el-switch
            v-model="row.guestEnabled"
            @change="enabledTo(row,'guest','')"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="disabled"
          ></el-switch>
        </template>
      </el-table-column>-->
      <el-table-column label="接收微信通知">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.wechatNoticeEnabled"
            @change="enabledTo(row, 'wechatNotice', '')"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="disabled"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="openId"
        label="微信openid"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="300" fixed="right">
        <template slot-scope="{ row }">
          <el-row>
            <el-col>
              <span v-if="row.delFlag">
                <el-link
                  type="danger"
                  :underline="false"
                  @click="handleClearFaceImg(row)"
                  >清除图片</el-link
                >-
              </span>
              <span v-else>
                <el-link
                  type="primary"
                  :underline="false"
                  @click="handleEdit(row)"
                  >编辑</el-link
                >-
                <el-link
                  type="danger"
                  :underline="false"
                  @click="handleDelete(row)"
                  >删除</el-link
                >-
                <span v-if="row.faceBound">
                  <el-link
                    type="primary"
                    :underline="false"
                    :disabled="true"
                    @click="uploadData(row)"
                    >数据同步</el-link
                  >-
                </span>
                <span v-if="!row.faceBound">
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="uploadData(row)"
                    >数据同步</el-link
                  >-
                </span>
              </span>
              <el-link
                type="primary"
                :underline="false"
                @click="bindDevice(row)"
                >关联设备</el-link
              >-
              <el-link type="primary" :underline="false" @click="showBound(row)"
                >已关联设备</el-link
              >
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-link
                type="primary"
                :underline="false"
                @click="showPersonCompany(row)"
                >关联班级</el-link
              >-
              <el-link
                type="primary"
                :underline="false"
                @click="bindWechat(row)"
                >微信绑定</el-link
              >-
              <el-link
                type="primary"
                :underline="false"
                @click="unbindWechat(row)"
                >微信解绑</el-link
              >-
              <el-link
                type="primary"
                :underline="false"
                @click="showLifeRecord(row)"
                >生活记录</el-link
              >-
              <el-link
                type="primary"
                :underline="false"
                @click="updateHealthyCode(row)"
                >更新健康码</el-link>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <personInfo-detail
      v-if="showModal"
      :businessKey="businessKey" 
      :title="modalTitle"
      :companyResult="companyResult"
      :companyPosition1="position1"
      :companyPosition2="position2"
      :companyPosition3="position3"
      :companyPosition4="position4"
      :companyPosition5="position5"
      @close="onDetailModalClose"
    ></personInfo-detail>
    <personDeviceRelation-list
      v-if="showDeviceModal"
      :personId="personId"
      @close="onDetailModalClose"
    ></personDeviceRelation-list>
    <personDeviceRelation-BoundList
      v-if="showBoundModal"
      :personId="personId"
      :delFlag="delFlag"
      @close="onDetailModalClose"
    ></personDeviceRelation-BoundList>
    <!-- 班级查看 start-->
    <personCompany-list
      v-if="showPersonCompanyModal"
      :teacherId="personId"
      :companyResult="companyResult"
      title="班级信息"
      @close="onDetailModalClose"
    ></personCompany-list>
    <!-- 班级查看 end-->
    <!--生活记录 start-->
    <lifeRecord-list
      v-if="showRecordModal"
      :personId="businessKey"
      @close="recordModalClose"
    ></lifeRecord-list>
    <!--生活记录 end-->
    <!--批量导入S-->
    <el-dialog
      title="批量导入人员"
      :visible.sync="batchImportVisible"
      :modal-append-to-body="false"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <el-form label-width="150px">
        <el-form-item label="模板下载">
          <el-link
            href="http://rccs.oss-cn-hangzhou.aliyuncs.com/smart/personInfo/2020/4/%E5%AD%A6%E6%A0%A1%E8%81%8C%E5%B7%A5%E4%BF%A1%E6%81%AF%E9%87%87%E9%9B%86%E8%A1%A8.xls"
            type="primary"
            target="_blank"
            >下载导入模板</el-link
          >
          <!-- --
          <el-link
            href="http://rccs.oss-cn-hangzhou.aliyuncs.com/smart/personInfo/2020/4/监管人员模板.xls"
            type="primary"
            target="_blank"
            >下载监管人员模板</el-link
          >--
          <el-link
            href="http://rccs.oss-cn-hangzhou.aliyuncs.com/smart/personInfo/2020/4/其他职工模板.xls"
            type="primary"
            target="_blank"
            >下载其他职工模板</el-link
          > -->
        </el-form-item>
        <el-form-item label="单位">
          <!-- <el-select
            v-model="uploadCompanyId"
            filterable
            placeholder="请选择"
            @change="changeUploadCompayId"
            style="width:280px"
          >
            <el-option
              v-for="company in companyResult"
              :key="company.id"
              :label="company.name"
              :value="company.id"
            ></el-option>
          </el-select>-->
          <el-select-tree
            v-model="uploadXlsData.companyId"
            :props="props"
            :options="companyResult"
            :value="uploadCompanyId"
            @input="getUploadSelectedValue($event)"
            height="200"
          ></el-select-tree
          >&nbsp;
        </el-form-item>
        <!-- <el-form-item label="类型">
          <el-select
            v-model="teacherType"
            @change="changeTeacherType"
            style="width: 280px"
          >
            <el-option label="班主任" value="1"></el-option>
            <el-option label="普通老师" value="2"></el-option>
            <el-option label="监管人员" value="3"></el-option>
            <el-option label="其他职业" value="4"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="上传文件">
          <el-upload
            class="upload-demo"
            accept=".xls"
            :action="uploadUrlXls"
            :data="uploadXlsData"
            :headers="headers"
            :on-preview="handleBatchImportPreview"
            name="uploadFile"
            :multiple="true"
            :limit="1"
            :on-remove="handleBatchImportRemove"
            :before-remove="beforeBatchImportRemove"
            :before-upload="beforeUpload"
            :on-exceed="handleBatchImportExceed"
            :on-success="handleBatchImportSuccess"
            :file-list="batchImportFileList"
          >
            <el-button size="small" type="primary" :loading="xlsLoading"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">
              只能上传xls文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="batchImportVisible = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
    <!--批量导入E-->
    <!-- 批量关联设备 -->
    <el-dialog
      title="关联设备"
      :visible.sync="batchBoundDevice"
      :modal-append-to-body="false"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="150px"
        v-loading="boundDeviceDisabled"
        element-loading-text="正在操作,请稍等"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-form-item label="是否立即同步">
          <el-select
            v-model="isSync"
            filterable
            placeholder="请选择"
            style="width: 120px"
            @change="changeSync"
          >
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备">
          <el-select v-model="boundDeviceId" filterable placeholder="请选择">
            <el-option
              v-for="devices in deviceResult"
              :key="devices.id"
              :label="devices.aliasName"
              :value="devices.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeBatchBoundDevice">关 闭</el-button>
        <el-button type="primary" @click="handleBatchBoundDevice"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 批量关联设备end -->
    <!-- 批量解绑设备 -->
    <el-dialog
      title="解绑设备"
      :visible.sync="batchUnBoundDevice"
      :modal-append-to-body="false"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <el-form label-width="150px" v-loading="boundDeviceDisabled">
        <el-form-item label="设备">
          <el-select v-model="boundUnDeviceId" filterable placeholder="请选择">
            <el-option
              v-for="devices in deviceResult"
              :key="devices.id"
              :label="devices.aliasName"
              :value="devices.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeBatchUnBoundDevice">关 闭</el-button>
        <el-button type="primary" @click="handleBatchUnBoundDevice"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 批量解绑设备end -->
    <el-dialog
      title="扫码绑定微信"
      :visible.sync="bindWechatVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      width="340px"
    >
      <div v-loading="bindWechatLoading">
        <img :src="bindWechatUrl" style="width: 300px; fit-object: cover" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Constant from "@/constant";
import PersonInfoDetail from "./personInfo-detail";
import PersonDeviceRelationList from "./personDeviceRelation-list";
import PersonDeviceRelationBoundList from "./personDeviceRelation-BoundList";
import PersonCompanyList from "./personCompany-list";
import LifeRecordList from "./lifeRecord-list";

import personInfoApi from "@/api/base/personInfo";
import companyInfoApi from "@/api/base/companyInfo";
import companyPositionApi from "@/api/base/companyPosition";
import deviceInfoApi from "@/api/base/deviceInfo";
import personDeviceRelationApi from "@/api/base/personDeviceRelation";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import permissionApi from "@/api/sys/permission";
import SelectTree from "@/components/SelectTree";
import pageUtil from "@/utils/page";
import { getToken } from "@/utils/auth"; // get token from cookie
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import axios from "axios";

export default {
  name: "BasePersonInfoList",
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        companyCode: "",
        parentId: "",
        name: "",
        phone: "",
        idCard: "",
        faceBound: "",
        isUploadPhoto: "",
        subordinate: false,
        position1: "",
        position2: "",
        position3: "",
        position4: "",
        position5: "",
        derviceName: "",
        popedom: "3,4,5,6,9",
        delFlag: "0",
        jobType: "",
        companyClassName: "",
        healthyCode:"",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 30, 100, 500],
      multipleSelection: [],
      healthyCodeList:[],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      companyResult: [],
      uploadCompanyId: "",
      batchImportVisible: false,
      batchImportFileList: [],
      uploadUrlXls: Constant.serverUrl + "/base/personInfo/importXls",
      //uploadUrlXls: Constant.serverUrl + "/base/personInfo/importClassTeacherXls",
      uploadXlsData: {
        subFolder: "company",
        companyId: "",
      },
      headers: {
        Authorization: getToken(),
      },
      disabled: false,
      position1: "",
      position2: "",
      position3: "",
      position4: "",
      position5: "",
      position1Show: false,
      position2Show: false,
      position3Show: false,
      position4Show: false,
      position5Show: false,
      showDeviceModal: false,
      showBoundModal: false,
      personId: "",
      batchBoundDevice: false,
      batchUnBoundDevice: false,
      deviceResult: "",
      boundDeviceId: "",
      boundUnDeviceId: "",
      xlsLoading: false,
      boundDeviceDisabled: false,
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      showPersonCompanyModal: false,
      teacherType: "",
      isSync: "2",
      popedomList: [],
      showRecordModal: false,
      bindWechatVisible: false,
      bindWechatUrl: "",
      bindWechatLoading: false,
      batchExportXls:false,
    };
  },
  created() {
    var self = this;

    var formData = new FormData();
    formData.append("catalogName", "健康码");
    dataDictionaryApi.findByCatalogName(formData).then(response => {
      var jsonData = response.data;
      this.healthyCodeList = jsonData.data;
    });

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });

    deviceInfoApi.list().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.deviceResult = jsonData.data;
        }
      }
    });

    permissionApi
      .hasPermission("post", "/base/personInfo/batchExportXls")
      .then((response) => {
        this.batchExportXls = response.data.result;
      });

    // companyPositionApi.detailForCompany().then(function(response) {
    //   var jsonData = response.data.data;
    //   if (jsonData.position1Name != null && jsonData.position1Name != "") {
    //     self.position1 = jsonData.position1Name;
    //     self.position1Show = true;
    //   }
    //   if (jsonData.position2Name != null && jsonData.position2Name != "") {
    //     self.position2 = jsonData.position2Name;
    //     self.position2Show = true;
    //   }
    //   if (jsonData.position3Name != null && jsonData.position3Name != "") {
    //     self.position3 = jsonData.position3Name;
    //     self.position3Show = true;
    //   }
    //   if (jsonData.position4Name != null && jsonData.position4Name != "") {
    //     self.position4 = jsonData.position4Name;
    //     self.position4Show = true;
    //   }
    //   if (jsonData.position5Name != null && jsonData.position5Name != "") {
    //     self.position5 = jsonData.position5Name;
    //     self.position5Show = true;
    //   }
    // });

    this.loadTree();
  },
  methods: {
    bindWechat(row) {
      this.bindWechatVisible = true;
      this.bindWechatLoading = true;
      this.bindWechatUrl = "";

      personInfoApi.bindWechat(row.id + "," + row.phone).then((response) => {
        var jsonData = response.data;
        this.bindWechatLoading = false;

        if (jsonData.result) {
          this.bindWechatUrl = jsonData.data;
        } else {
          this.$message.warning(jsonData.message);
        }
      });
    },
    // downTemplate() {
    //   let rulsss =
    //     "http://rccs.oss-cn-hangzhou.aliyuncs.com/smart/personInfo/2020/4/27094007977.xls";

    //   const link = document.createElement("a");
    //   link.href = rulsss;
    //   link.download = "asd";
    //   link.title="aaaaa"
    //   link.click();
    // },
    getUploadSelectedValue(value) {
      this.uploadCompanyId = value;
      this.uploadXlsData.companyId = value;
    },
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    changePage(pageIndex) {
      var self = this;
      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      if (self.queryModel.parentId == null) {
        self.queryModel.parentId = "";
      }
      formData.append("companyCode", self.queryModel.companyCode);
      formData.append("parentId", self.queryModel.parentId);

      formData.append("subordinate", self.queryModel.subordinate);

      formData.append("id", self.queryModel.id);
      formData.append("name", self.queryModel.name);
      formData.append("phone", self.queryModel.phone);
      formData.append("idCard", self.queryModel.idCard);
      formData.append("faceBound", self.queryModel.faceBound);
      formData.append("isUploadPhoto", self.queryModel.isUploadPhoto);

      formData.append("position1", self.queryModel.position1);
      formData.append("position2", self.queryModel.position2);
      formData.append("position3", self.queryModel.position3);
      formData.append("position4", self.queryModel.position4);
      formData.append("position5", self.queryModel.position5);

      formData.append("derviceName", self.queryModel.derviceName);

      formData.append("popedom", self.queryModel.popedom);
      formData.append("delFlag", self.queryModel.delFlag);
      formData.append("jobType", self.queryModel.jobType);

      if (self.queryModel.companyClassName == null) {
        self.queryModel.companyClassName = "";
      }
      formData.append("companyClassName", self.queryModel.companyClassName);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }
      formData.append("healthyCode", self.queryModel.healthyCode);

      personInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;

          //页面高度-列表上面的高度-分页栏高度
          //45为分页栏的高度
          // self.tableHeight =
          //   window.innerHeight - self.$refs.formTable.$el.offsetTop - 45;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.changePage(this.pageIndex);
    },
    sortChange(data) {
      this.field = data.column.field;
      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;
      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          self.loading = true;
          personInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          self.loading = false;
        });
    },
    handleBatchDelete() {
      var self = this;
      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        self.loading = true;
        personInfoApi
          .batchRemove(idList)
          .then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          })
          .catch(() => {
            self.loading = false;
          });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      this.showDeviceModal = false;
      this.showBoundModal = false;
      this.showPersonCompanyModal = false;
      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    changeUploadCompayId(value) {
      var self = this;
      self.uploadXlsData.companyId = value;
    },
    bindDevice(record) {
      //关联设备
      this.personId = record.id;
      this.showDeviceModal = true;
    },
    showBound(record) {
      //展示关联设备
      //人员信息ID
      this.personId = record.id;
      this.delFlag = this.queryModel.delFlag;
      this.showBoundModal = true;
    },
    beforeUpload(file, fileList) {
      //导入前判断
      var self = this;
      if (self.uploadCompanyId == null || self.uploadCompanyId == "") {
        self.$message({
          type: "warning",
          message: "请选择单位",
        });
        self.xlsLoading = false;
        return false;
      } else {
        self.xlsLoading = true;
        return true;
      }
    },
    //批量导入-上传成功
    handleBatchImportSuccess(response, file, fileList) {
      var self = this;
      self.xlsLoading = false;
      if (response.result) {
        self.$message.success(response.message);
        this.batchImportFileList = [];
        this.changePage(1);
        this.uploadCompanyId = "";
        this.uploadXlsData.companyId = "";
        this.batchImportVisible = false;
      } else {
        //this.$message.error(response.message);
        this.batchImportFileList = [];
        this.changePage(1);
        this.uploadCompanyId = "";
        this.uploadXlsData.companyId = "";

        if (response.data != null) {
          //下载有错误信息提示的报表
          //window.open(response.data);
          self.$message({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message:
              response.message +
              `,<a href="${response.data}" target="_blank">点击下载未导入的数据报表</a>&nbsp;`,
            duration: 30000,
          });
        }
      }
    },
    //批量导入-预览
    handleBatchImportPreview(file) {
      console.log(file.url);
    },
    //批量导入-移除
    handleBatchImportRemove(file, fileList) {
      console.log(file, fileList);
    },
    //批量导入-移除前操作
    beforeBatchImportRemove(file, fileList) {
      console.log(file, fileList);
    },
    //批量导入-文件超出个数限制时的钩子
    handleBatchImportExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    importClose() {},
    downloadAll() {
      //导出
      var self = this;
      var formData = new FormData();

      if (self.queryModel.parentId == null) {
        self.queryModel.parentId = "";
      }
      formData.append("companyCode", self.queryModel.companyCode);
      formData.append("parentId", self.queryModel.parentId);

      formData.append("subordinate", self.queryModel.subordinate);

      formData.append("id", self.queryModel.id);
      formData.append("name", self.queryModel.name);
      formData.append("phone", self.queryModel.phone);
      formData.append("idCard", self.queryModel.idCard);
      formData.append("faceBound", self.queryModel.faceBound);
      formData.append("isUploadPhoto", self.queryModel.isUploadPhoto);

      formData.append("position1", self.queryModel.position1);
      formData.append("position2", self.queryModel.position2);
      formData.append("position3", self.queryModel.position3);
      formData.append("position4", self.queryModel.position4);
      formData.append("position5", self.queryModel.position5);

      formData.append("derviceName", self.queryModel.derviceName);

      formData.append("popedom", self.queryModel.popedom);
      formData.append("delFlag", self.queryModel.delFlag);
      formData.append("jobType", self.queryModel.jobType);

      if (self.queryModel.companyClassName == null) {
        self.queryModel.companyClassName = "";
      }
      formData.append("companyClassName", self.queryModel.companyClassName);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }
      formData.append("healthyCode", self.queryModel.healthyCode);

      self.xlsLoading = true;

      personInfoApi.exportXls(formData).then(function (response) {
        self.xlsLoading = false;
        var jsonData = response.data;
        if (jsonData.result) {
          if (jsonData.data != null) {
            self.$message({
              showClose: true,
              type: "success",
              dangerouslyUseHTMLString: true,
              message: `报表已生成，<a href="${jsonData.data}" target="_blank">点击下载报表</a>&nbsp;`,
              duration: 30000,
            });
          }
        }
      });
    },
    enabledTo(row, type, value) {
      var self = this;

      if (type == "wechatNotice") {
        var formData = new FormData();
        formData.append("id", row.id);

        personInfoApi.enabledWechatNotice(formData);
      } else {
        this.$confirm("该操作会对设备上的信息进行操作,请确认？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            //self.loading = true;
            if (!self.disabled) {
              var formData = new FormData();
              formData.append("id", row.id);
              if ("face" == type) {
                personInfoApi.enabledFace(formData);
              } else if ("card" == type) {
                personInfoApi.enabledCard(formData);
              } else if ("app" == type) {
                personInfoApi.enabledApp(formData);
              } else if ("guest" == type) {
                personInfoApi.enabledGuest(formData);
              } else if ("wechatNotice" == type) {
                personInfoApi.enabledWechatNotice(formData);
              } else if ("sync" == type) {
                personInfoApi.enabledSync(formData);
              }

              //关闭开关,是自动同步到服务器
              //打开开关需要手动同步服务器
              // if (!value) {
              //   //如果是打开开关,则修改状态
              //   row.faceBound = false;
              // }

              self.disabled = true;

              setTimeout(function () {
                self.disabled = false;
                //self.loading = false;
              }, 1000); //一秒内不能重复点击
            } else {
              self.$message({
                type: "success",
                message: "操作过快!",
              });
            }
          })
          .catch(() => {
            // self.loading = false;
            if (row.faceEnabled) {
              row.faceEnabled = false;
            } else {
              row.faceEnabled = true;
            }
          });
      }
    },
    uploadData(record) {
      //同步人脸数据
      var self = this;
      self.loading = true;
      var uploadSync = new Array(0);
      uploadSync.push(record.id);

      personInfoApi.dataSync(uploadSync).then(function (response) {
        var jsonData = response.data;
        self.loading = false;
        if (jsonData.result) {
          if (jsonData.data) {
            self.changePage(self.pageIndex);
            self.$message({
              type: "success",
              message: "同步成功!",
            });
          } else {
            if (jsonData.message != null) {
              //下载有错误信息提示的报表
              //window.open(response.data);
              self.$message({
                showClose: true,
                dangerouslyUseHTMLString: true,
                message:
                  "错误" +
                  `,<a href="${jsonData.message}" target="_blank">点击下载错误报表</a>&nbsp;`,
                duration: 30000,
              });
            }
          }
        } else {
          self.$message({
            type: "warning",
            message: jsonData.message,
          });
        }
      });
    },
    dataSync() {
      //批量同步人脸
      var self = this;
      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认同步选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          self.loading = true;
          personInfoApi.dataSync(idList).then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              if (jsonData.data) {
                self.changePage(self.pageIndex);
                self.$message({
                  type: "success",
                  message: "同步成功!",
                });
              } else {
                if (jsonData.message != null) {
                  //下载有错误信息提示的报表
                  //window.open(response.data);
                  self.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message:
                      "错误" +
                      `,<a href="${jsonData.message}" target="_blank">点击下载错误报表</a>&nbsp;`,
                    duration: 30000,
                  });
                }
              }
            } else {
              self.$message({
                type: "warning",
                message: jsonData.message,
              });
            }
          });
        })
        .catch(() => {
          self.loading = false;
        });
    },
    handleBatchBoundDevice() {
      //批量人脸
      var self = this;
      //self.loading = true;
      self.boundDeviceDisabled = true;

      let boundDeviceId = self.boundDeviceId;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });
      var formData = new FormData();

      formData.append("persons", idList);
      formData.append("deviceId", boundDeviceId);
      formData.append("isSync", self.isSync);
      personDeviceRelationApi
        .batchBindPerson(formData)
        .then(function (response) {
          var jsonData = response.data;
          //self.loading = false;
          self.boundDeviceDisabled = false;
          if (jsonData.result) {
            self.changePage(self.pageIndex);
            self.batchBoundDevice = false;
            self.boundDeviceId = "";
            self.$message({
              type: "success",
              message: "关联成功!",
            });
          } else {
            self.$message({
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: `有部分人员关联失败,<a href="${jsonData.message}" target="_blank">点击下载错误信息报表</a>&nbsp;`,
              duration: 30000,
            });
          }
        });
    },
    handleBatchUnBoundDevice() {
      //批量人脸
      var self = this;
      //self.loading = true;
      self.boundDeviceDisabled = true;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });
      var formData = new FormData();

      formData.append("persons", idList);
      formData.append("deviceId", self.boundUnDeviceId);
      personDeviceRelationApi
        .batchUnBindPerson(formData)
        .then(function (response) {
          var jsonData = response.data;
          //self.loading = false;
          self.boundDeviceDisabled = false;
          if (jsonData.result) {
            self.changePage(self.pageIndex);
            self.batchBoundDevice = false;
            self.boundDeviceId = "";
            self.$message({
              type: "success",
              message: "解除关联成功!",
            });
          }
        });
    },
    closeBatchBoundDevice() {
      var self = this;
      self.batchBoundDevice = false;
      self.boundDeviceId = "";
    },
    closeBatchUnBoundDevice() {
      var self = this;
      self.batchUnBoundDevice = false;
      self.boundUnDeviceId = "";
    },
    handleBatchEnabledFace() {
      //开启自动同步
      var self = this;
      self.loading = true;

      let boundDeviceId = self.boundDeviceId;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });
      var formData = new FormData();

      formData.append("ids", idList);

      personInfoApi.enabledSyncList(idList).then(function (response) {
        var jsonData = response.data;
        self.loading = false;
        if (jsonData.result) {
          self.changePage(self.pageIndex);
          self.$message({
            type: "success",
            message: "操作成功!",
          });
        } else {
          self.$message({
            type: "warning",
            message: jsonData.message,
          });
        }
      });
    },
    showPersonCompany(record) {
      this.showPersonCompanyModal = true;
      this.personId = record.id;
    },
    changeTeacherType() {
      if (this.teacherType == "1") {
        this.uploadUrlXls =
          Constant.serverUrl + "/base/personInfo/importClassTeacherXls";
      } else if (this.teacherType == "2") {
        this.uploadUrlXls =
          Constant.serverUrl + "/base/personInfo/importGeneralTeacherXls";
      } else if (this.teacherType == "3") {
        this.uploadUrlXls =
          Constant.serverUrl + "/base/personInfo/importManageXls";
      } else if (this.teacherType == "4") {
        this.uploadUrlXls =
          Constant.serverUrl + "/base/personInfo/importOtherXls";
      }
    },
    changeSync(value) {
      var self = this;
      self.isSync = value;
    },
    handleClearFaceImg(record) {
      var self = this;
      self
        .$confirm("是否确认清除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          self.loading = true;
          personInfoApi.clearFaceImg(record.id).then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              self.$message({
                type: "success",
                message: "清除成功!",
              });
            } else {
              self.$message({
                showClose: true,
                dangerouslyUseHTMLString: true,
                message:
                  "发生错误" +
                  `,<a href="${jsonData.message}" target="_blank">点击下载错误信息</a>&nbsp;`,
                duration: 30000,
              });
            }
          });
        })
        .catch(() => {
          self.loading = false;
        });
    },
    handleBatchClearFaceImg() {
      var self = this;
      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认清除设备上的照片？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        self.loading = true;
        personInfoApi
          .batchClearFaceImg(idList)
          .then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "清除成功!",
              });
            }
          })
          .catch(() => {
            self.loading = false;
          });
      });
    },
    unbindWechat(record) {
      var self = this;
      this.$confirm("是否确认微信解绑？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        self.loading = true;
        personInfoApi
          .unbindWechat(record.id)
          .then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "解绑成功!",
              });
            }
          })
          .catch(() => {
            self.loading = false;
          });
      });
    },
    showLifeRecord(record) {
      this.showRecordModal = true;
      this.businessKey = record.id;
    },

    recordModalClose(refreshed) {
      this.showRecordModal = false;
    },

    updateHealthyCode(record) {
      var self = this;
      self.$message.success("因大数据局接口升级，暂时无法获取到最新健康码数据，给您带来不便，还望见谅！");
      // self.loading = true;

      // personInfoApi.updateHealthyCode(record.id).then(response=>{
      //   self.loading = false;

      //   var jsonData = response.data;
            
      //   if (jsonData.result) {
      //     self.$message.success("更新健康码状态成功!");
      //     self.changePage(self.pageIndex);
      //   }
      //   else{
      //     self.$message.success("更新健康码状态失败!");
      //   }
      // });
    }
  },
  async mounted() {
    var self = this;

    this.changePage(1);
    //self.tableHeight = window.innerHeight - self.$refs.queryForm.$el.offsetTop - 100;
  },
  components: {
    "personInfo-detail": PersonInfoDetail,
    "personDeviceRelation-list": PersonDeviceRelationList,
    "personDeviceRelation-BoundList": PersonDeviceRelationBoundList,
    "el-select-tree": SelectTree,
    "personCompany-list": PersonCompanyList,
    "lifeRecord-list": LifeRecordList,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>